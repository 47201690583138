<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="d-flex justify-content-between border-bottom mb-1 text-secondary">
      <small class="header-text">Passengers</small>
      <small class="header-text text-center">Base Fare</small>
      <small class="header-text text-right">Tax</small>
    </div>
    <div class="fare-content-wrapper">
      <div
        v-for="(passenger, passengerIndex) in passengers"
        :key="passengerIndex"
        class="content-item d-flex justify-content-between price-info"
      >
        <span
          v-if="passenger.code === 'ADT'"
          class="passenger-type"
        >
          Adult x {{ passenger.count }}
        </span>
        <span
          v-if="passenger.code === 'CNN'"
          class="passenger-type"
        >
          Child x {{ passenger.count }}
        </span>
        <span
          v-if="passenger.code === 'INF'"
          class="passenger-type"
        >
          Infant x {{ passenger.count }}
        </span>
        <span class="flight-price text-center">
          {{ currency }} {{ Number(Math.ceil(passenger.total_base_price))
            .toLocaleString('en') }}
        </span>
        <span class="flight-price text-right">
          {{ currency }}
          {{ Number(Math.ceil(passenger.total_taxes)).toLocaleString('en') }}
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  name: 'FlightFareBreakDown',
  props: {
    passengers: {
      type: Array,
      default: () => ([]),
    },
    currency: {
      type: String,
      default: 'BDT',
    },
  },
}
</script>

<style lang="scss" scoped>
.header-text, .price-info span {
  flex-basis: calc( 100% / 3);
}
</style>
