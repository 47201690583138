<template>
  <b-card
    class="mb-0"
    no-body
  >
    <div class="d-flex justify-content-between border-bottom mb-1 text-secondary">
      <small class="header-text">Flight</small>
      <small class="header-text text-center">Cabin</small>
      <small class="header-text text-right">Check-in</small>
    </div>
    <div
      v-for="(flight, f) in flights"
      :key="flight + f"
    >
      <div
        v-for="(segment, s) in flight.options[0].segments"
        :key="segment + s"
        class="d-flex justify-content-between baggage-info"
      >
        <span class="segment-loc">
          {{ segment.origin }} - {{ segment.destination }}
        </span>
        <span class="text-center">
          {{ getBaggageAllowance(segment.baggage_allowance, 'CarryOn') }}
        </span>
        <span class="text-right">
          {{ getBaggageAllowance(segment.baggage_allowance, 'CheckIn') }}
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
import helper from '@/mixins/helper'

export default {
  name: 'FlightBaggage',
  mixins: [helper],
  props: {
    flights: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    getBaggageAllowance(allowances, type) {
      const allowanceData = Array.isArray(allowances) ? allowances.find(item => item.Type === 'ADT') : false
      if (allowanceData && allowanceData[type]) {
        const weightKey = `${type}BaggageWeight`
        const quantityKey = `${type}BaggageQuantity`
        const metrics = allowanceData.Metrics || 'Kg'
        const weight = allowanceData[weightKey] ? `${allowanceData[weightKey]} ${metrics}` : ''
        // eslint-disable-next-line no-nested-ternary
        const quantity = weight !== '' ? allowanceData[quantityKey] && Number(allowanceData[quantityKey]) > 1
          ? Number(allowanceData[quantityKey]) : '' : Number(allowanceData[quantityKey])
        if (quantity) {
          if (weight) {
            return `${quantity} - ${weight}`
          }
          return `${quantity} ${this.checkForPlural(quantity, 'piece', 'pieces')}`
        }
        return `${weight}`
      }
      return 'N/A'
    },
  },
}
</script>

<style lang="scss" scoped>
.header-text, .baggage-info span {
  flex-basis: calc( 100% / 3);
}
</style>
