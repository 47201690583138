<template>
  <b-card
    v-if="(trip && trip.flight_details) || (trip && trip.flights)"
    class="border rounded"
    no-body
  >
    <div
      v-if="!isSelectable"
      class="price-info p-1"
    >
      <div class="price">
        <span>Total:</span>
        BDT {{ (Number(trip.flight_details.total_price)).toLocaleString() }}
      </div>
    </div>
    <b-tabs class="p-1">
      <b-tab
        v-for="(flight, f) in flights"
        :key="f"
      >
        <template #title>
          <span>{{ flight.origin }} -{{ flight.destination }}</span>
        </template>
        <div
          v-for="(segment, segmentIndex) in flight.options[0].segments"
          :key="segmentIndex + segment + 'segment'"
          class="flight-airlines-info"
        >
          <div class="flight-airlines-info">
            <div class="airplane-info-wrapper">
              <div class="airplane-info">
                <div class="image-placeholder">
                  <img
                    v-if="segment.carrier.logo"
                    class="flight-airlines-logo"
                    :src="segment.carrier.logo"
                  >
                </div>
                <div class="airplane-info-text">
                  <span
                    v-if="segment.carrier.name"
                    class="airplane-name"
                  >
                    {{ segment.carrier.name }}
                  </span>
                  <span
                    v-else
                    class="airplane-name"
                  >Airlines Name</span>
                  <span class="airplane-model">
                    {{ segment.carrier.code }}
                    {{ segment.flight_number }} {{ segment.equipment_display_name
                      ? ` | ${segment.equipment_display_name}` : '' }}
                  </span>
                </div>
              </div>
            </div>
            <div class="flight-time detail-time">
              <div class="start-time">
                <span class="time-text">{{ formatDate(segment.original_departure_time, 'HH:mm') }}</span>
                <span class="day-text">{{ formatDate(segment.original_departure_time, 'ddd, DD MMM, Y') }}</span>
                <span class="destination-text">{{ segment.origin }}</span>
              </div>
              <div class="stops-info">
                <div class="stop-names">
                  <span>Non Stop</span>
                </div>
                <div class="arrow-pointer" />
                <div class="stop-names">
                  <span class="duration-text">{{ formatTime(segment.flight_time) }}</span>
                </div>
              </div>
              <div class="end-time">
                <span class="time-text">{{ formatDate(segment.original_arrival_time, 'HH:mm') }}</span>
                <span class="day-text">{{ formatDate(segment.original_arrival_time, 'ddd, DD MMM, Y') }}</span>
                <span class="destination-text">{{ segment.destination }}</span>
              </div>
            </div>
            <div
              v-if="flight.options[0].stoppage_locations
                ? segmentIndex <= flight.options[0].stoppage_locations.length - 1
                : false"
              class="flight-change"
            >
              <span class="static-text">Change plane at</span>
              <span class="change-location">
                {{ flight.options[0].stoppage_locations[segmentIndex].airport_code }} |
                {{ formatTime(flight.options[0].stoppage_locations[segmentIndex].layover_time) }}
                layover
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="isSelectable"
          class="d-flex my-1 py-1 border-top"
          :class="status === 'PENDING' ? 'justify-content-between':'justify-content-end'"
        >
          <b-button
            v-if="status === 'PENDING'"
            variant="primary"
            @click="getSelectedTrip()"
          >
            Select
          </b-button>
          <p class="price">
            <small class="text-muted">Total Price: </small>
            BDT {{ isSelectable ? Number(trip.total_price).toLocaleString() : (Number(trip.flight_details.total_price)).toLocaleString() }}
          </p>
        </div>
      </b-tab>
    </b-tabs>
    <div class="d-flex justify-content-end bg-light p-1">
      <div
        role="tablist"
        class="collapse-icon position-relative"
      >
        <span
          v-b-toggle.flight-details
          role="tab"
          data-toggle="collapse"
          class="details-toggle-btn cursor-pointer pr-3 font-weight-bold"
        >Baggage & Fare</span>
      </div>
    </div>
    <b-collapse
      id="flight-details"
      role="tabpanel"
    >
      <b-tabs class="p-1">
        <b-tab>
          <template #title>
            <span>Baggage</span>
          </template>
          <flight-baggage
            :flights="flights"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <span>Fare</span>
          </template>
          <flight-fare-break-down
            :currency="trip.converted_currency_code"
            :passengers="trip.flight_details.passengers"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <span>Policy</span>
          </template>
          <div>
            <div
              v-if="trip.flight_type === 'DOM'"
              class="fare-rule-content"
            >
              <template>
                <div class="text-primary font-weight-bolder border-bottom">
                  <span>Cancellation</span>
                </div>
                <div class="fee-content mb-1">
                  <span>
                    Refund Amount = Paid Amount - Airline's Cancellation Fee
                  </span>
                </div>
              </template>
              <template>
                <div class="text-primary font-weight-bolder border-bottom">
                  <span>Re-issue</span>
                </div>
                <div class="fee-content">
                  <span>
                    Re-issue Fee = Airline's Fee + Fare Difference
                  </span>
                </div>
              </template>
            </div>
            <div
              v-else
              class="fare-rule-content"
            >
              <template>
                <div class="text-primary font-weight-bolder border-bottom">
                  <span>Cancellation</span>
                </div>
                <div class="fee-content mb-1">
                  <span class="d-block">
                    Cancellation Fee = Airline's Fee + GoZayaan Fee
                  </span>
                  <span>Refund Amount = Paid Amount - Cancellation Fee</span>
                </div>
              </template>
              <template>
                <div class="text-primary font-weight-bolder border-bottom">
                  <span>Re-issue</span>
                </div>
                <div class="fee-content">
                  <span>
                    Re-issue Fee = Airline's Fee + Fare Difference + GoZayaan Fee
                  </span>
                </div>
              </template>
            </div>
            <small class="cancellation-note my-2">
              *The airline's fee is indicative and per person. Convenience
              fee is non-refundable.
            </small>
          </div>
        </b-tab>
      </b-tabs>
    </b-collapse>
  </b-card>
</template>

<script>
import helper from '@/mixins/helper'
import FlightBaggage from '@/components/flight/FlightBaggage.vue'
import FlightFareBreakDown from '@/components/flight/FlightFareBreakDown.vue'

export default {
  name: 'FlightCard',
  components: { FlightFareBreakDown, FlightBaggage },
  mixins: [helper],
  props: {
    trip: {
      type: Object,
    },
    invoice: {
      type: Object,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
    },
  },
  computed: {
    flights() {
      if (this.trip && this.trip.flight_details) {
        return this.trip.flight_details.flights
      }
      return this.trip ? this.trip.flights : []
    },
  },
  methods: {
    formatTime(minutesValue) {
      const hours = Math.floor(minutesValue / 60)
      const min = minutesValue % 60
      if (hours === 0) {
        return `${min}min`
      } if (min === 0) {
        return `${hours}h`
      }
      return `${hours}h ${min}min`
    },
    getAirPlaneModelName(equipmentNumber) {
      const firstDigitAsString = String(equipmentNumber).charAt(0)
      const firstDigit = Number(firstDigitAsString)
      return firstDigit === 7 ? `Boeing ${equipmentNumber}` : `Airbus ${equipmentNumber}`
    },
    getSelectedTrip() {
      this.$emit('getSelectedTrip', this.trip)
    },
  },
}
</script>

<style lang="scss" scoped>
.price-info {
  .commission-amount {
    font-weight: 600;
    span {
      opacity: 0.6;
    }
  }
}
.price {
  font-size: 16px;
  font-weight: 600;
  color: #7367f0;
  margin: 5px 0;
}
.flight-airlines-info {
  .airplane-info-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    .airplane-info {
      .image-placeholder {
        width: 50px;
        height: 50px;
        margin-right: 12px;
        display: inline-block;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        img.flight-airlines-logo {
          vertical-align: bottom;
        }
      }
      .airplane-info-text {
        display: inline-block;
        span {
          &.airplane-name {
            color: #7367f0;
          }

          &.airplane-model {
            color: #546378;
            display: block;
          }
        }
      }
    }
    .airplane-amenities {
      color: #546378;
    }
  }
  .detail-time {
    justify-content: space-between;
    margin-top: 12px;
    border-top: 1px solid #bcc9dc;
    padding-top: 12px;
  }
  .flight-change {
    background-color: #ecf3fe;
    text-align: center;
    padding: 8px 18px;
    margin: 8px 0 20px;
    border-radius: 5px;
    .static-text {
      color: #546378;
    }
    .change-location {
      color: #7367f0;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .flight-time {
    display: flex;
    .stops-info {
      .stop-names {
        position: relative;
        .airplane-change-tooltip {
          display: none;
          position: absolute;
          background-color: #fff;
          z-index: 50;
          border: 1px solid #e7ebed;
          box-shadow: 0 2px 5px 0 rgba(113, 118, 116, 0.08);
          border-radius: 5px;
          padding: 8px 12px;
          width: 100%;
          bottom: calc(100% + 22px);
          left: 45%;
          transform: translateX(-50%);
          span {
            display: block;
          }
          .tooltip-header {
            color: #728db6;
          }
          .tooltip-content {
            color: #2e538a;
          }
          &:after, &:before {
            content: "";
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            top: 100%;
            left: 50%;
            margin-left: -10px;
          }
          &:before {
            border-top: 10px solid #e7ebed;
            margin-top: 0;
          }
          &:after {
            border-top: 10px solid white;
            margin-top: -2px;
            z-index: 1;
          }
        }
      }
      &:hover {
        .airplane-change-tooltip {
          display: inline-block;
        }
      }
      .stop-numbers, .stop-names {
        color: #728db6;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        .duration-text {
          color: #728db6;
          font-weight: 600;
          display: block;
          margin-top: 8px;
        }
        .stop-text {
          position: relative;
          margin-right: 5px;
        }
        .stop-text:before {
          content: '';
          position: absolute;
          top: calc(100% - 25px);
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: #fff;
          border: 1px solid #728db6;
        }
      }

      .arrow-pointer {
        background-color: #728db6;
        height: 1.5px;
        width: 100px;
        position: relative;
        margin: 0 18px;
        display: inline-block;

        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 8px 8px 0 0;
          border-color: #728db6 transparent transparent transparent;
          right: 0;
          top: -8px;
          transform: scaleY(-1);
        }
      }
    }

    .start-time, .end-time {
      .time-text {
        color: #7367f0;
        font-size: 18px;
        font-weight: 600;
      }

      .destination-text, .day-text {
        display: block;
        color: #546378;
      }
    }

    .start-time {
      text-align: left;
      flex-basis: 30%;
    }

    .end-time {
      text-align: right;
      flex-basis: 30%;
    }
  }
}
</style>
